
.home-page-main-container {
  .home-page-popular-cards {
    top: -240px;
    margin-bottom: 80px;
    z-index: 10;
    position: absolute;

    @media (max-width: 1375px) {
      top: -210px;
    }
    @media (max-width: 1000px) {
      top: -179px;
    }
    @media (max-width: 950px) {
      position: static;
      margin-bottom: 0;
      top: 0;
    }

    .card-container {
      @media (max-width: 950px) {
        position: relative;
        padding-bottom: 80px;
        border-radius: 0px 180px 0px 0px;
        background-image: linear-gradient(
          to top,
          rgba(34, 176, 252, 0.06),
          rgba(34, 176, 252, 0.03)
        );
      }
      @media (max-width: 950px) {
        border-radius: 0px 140px 0px 0px;
      }
    }

    .card-container-inner-main {
      @media (max-width: 950px) {
        position: relative;
        top: -218px;
      }
      @media (max-width: 575px) {
        top: -280px;
      }
      @media (max-width: 500px) {
        top: -269px;
      }
      @media (max-width: 400px) {
        top: -248px;
      }
    }
  }

  .absolute-image-category-0 {
    bottom: 48px;
    width: 340px;
    margin: 0 auto;
    right: 48px;
    text-align: right;

    @media (max-width: 1550px) {
      right: 40px;
      bottom: 40px;
    }
    @media (max-width: 450px) {
      width: 100%;
      right: 0;
      left: 0;
      text-align: center;
      padding-right: 10px;
      padding-left: 10px;
    }

    .absolute-image-category-text {
      @media (max-width: 450px) {
        max-width: 311px;
        margin: 0 auto;
      }
      @media (max-width: 365px) {
        max-width: 255px;
      }
    }

    .absolute-image-category-name {
      text-align: right;

      @media (max-width: 450px) {
        max-width: 311px;
        margin: 0 auto;
        margin-top: 20px;
      }
      @media (max-width: 365px) {
        max-width: 255px;
      }
    }
  }

  .absolute-image-category-1 {
    bottom: 48px;
    width: 340px;
    margin: 0 auto;
    left: 48px;
    text-align: left;

    @media (max-width: 1550px) {
      left: 40px;
      bottom: 40px;
    }
    @media (max-width: 1000px) {
      right: 48px;
      left: auto;
      text-align: right;
    }
    @media (max-width: 450px) {
      width: 100%;
      right: 0;
      left: 0;
      text-align: center;
      padding-right: 10px;
      padding-left: 10px;
    }

    .absolute-image-category-text {
      @media (max-width: 450px) {
        max-width: 275px;
        margin: 0 auto;
      }
    }

    .absolute-image-category-name {
      text-align: left;

      @media (max-width: 1000px) {
        text-align: right;
      }

      @media (max-width: 450px) {
        max-width: 275px;
        margin: 0 auto;
        text-align: right;
        margin-top: 20px;
      }
    }
  }

  .absolute-image-category-2 {
    bottom: 48px;
    width: 330px;
    margin: 0 auto;
    right: 48px;
    text-align: right;

    @media (max-width: 1550px) {
      right: 40px;
      bottom: 40px;
      width: 302px;
    }
    @media (max-width: 450px) {
      width: 100%;
      right: 0;
      left: 0;
      text-align: center;
      padding-right: 10px;
      padding-left: 10px;
    }

    .absolute-image-category-text {
      @media (max-width: 450px) {
        max-width: 225px;
        margin: 0 auto;
      }
      @media (max-width: 365px) {
        max-width: 185px;
      }
    }

    .absolute-image-category-name {
      text-align: right;

      @media (max-width: 450px) {
        max-width: 225px;
        margin: 0 auto;
        margin-top: 20px;
      }
      @media (max-width: 365px) {
        max-width: 185px;
      }
    }
  }

  .main-container-home-category-0 {
    .home-category-header-container {
      @media (max-width: 1150px) {
        padding-right: 50px;
      }
      @media (max-width: 1000px) {
        padding-right: 0px;
      }
    }
  }
}

.featured-press {
  height: 23px;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  cursor: pointer;
  &:hover {
    filter: grayscale(0%);
    opacity: 1;
    transition: opacity 0.2s ease-in;
  }
}

.featured-in-link-xtraLarge {
  @media (max-width: 1400px) {
    display: none !important;
  }
}

.featured-in-link-large {
  @media (max-width: 950px) {
    display: none !important;
  }
}

.featured-in-link-medium {
  @media (max-width: 800px) {
    display: none !important;
  }
}

.featured-in-link-medium-small {
  @media (max-width: 576px) {
    display: none !important;
  }
}

.hover-white-button {
  &:hover {
    background-color: #fff9ef !important;
    color: #ff9c1b !important;
  }
}

.homePageSearchContainer {
  .main-default-orange-button {
    height: 72px;
  }
}

.home-search-amount-input,
.home-search-merchant-input {
  &::-webkit-input-placeholder {
    color: rgb(170, 170, 170);
  }
  &:-ms-input-placeholder {
    color: rgb(170, 170, 170);
  }
  &::placeholder {
    color: rgb(170, 170, 170);
  }
}

.shrink-trans {
  transition: all 0.1s ease-in-out;
}

.fp-card-details {
  margin-left: 0px;
  line-height: 20px;
  color: #999999;
  list-style: none;
  font-size: 14px;
  padding-left: 0px;
  width: 100%;
  margin-bottom: 0;

  b {
    color: #3cb9d5;
  }
}
